<template>
  <section id="Socialservice" class="services">
    <div class="container">
      <div class="section-title">
        <h2>Vatican News</h2>
      </div>

      <div class="row" style="padding-top: 30px">
        <div
          class="col-lg-4 col-md-4 col-12 d-flex align-items-stretch"
          v-for="(obj, key) in mobileappJSON"
          :key="key"
        >
          <div class="icon-box">
            <Image :fpath="obj.path" class="serviceimage" />
            <h4 style="font-size: 15px">{{ obj.header }}</h4>
            <p>{{ obj.caption }}</p>
            <a href="../vatican-news/saint-rita-of-cascia" class="saintreadmore"
              >Read More</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Image from "@/components/Image.vue";
export default {
  components: {
    Image,
  },
  data: () => ({
    mobileappJSON: [
      {
        path: "saint-rita-of-cascia.jpg",
        header: "Saint Rita of Cascia",
        caption:
          "Like Elizabeth Ann Seton, Rita of Cascia was a wife, mother, widow, and member of a religious community. Her holiness was reflected in each phase of her life...",
      },
      {
        path: "saint-rita-of-cascia.jpg",
        header: "Saint Rita of Cascia",
        caption:
          "Like Elizabeth Ann Seton, Rita of Cascia was a wife, mother, widow, and member of a religious community. Her holiness was reflected in each phase of her life...",
      },
      {
        path: "saint-rita-of-cascia.jpg",
        header: "Saint Rita of Cascia",
        caption:
          "Like Elizabeth Ann Seton, Rita of Cascia was a wife, mother, widow, and member of a religious community. Her holiness was reflected in each phase of her life...",
      },
    ],
  }),
};
</script>
<style>
.saintreadmore {
  color: #0880e8;
  font-weight: 500;
}
.saintreadmore:hover {
  color: #000;
}
img.serviceimage {
  width: 100%;
  padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Socialservice {
  padding: 50px 30px 10px 30px;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
}

.services .icon-box h4 {
  color: #000;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}
.icon-box:hover {
  background: #f7f9fd;
  border-radius: 10px;
  transform: translateY(-2px);
  border: 1px solid #4a90e2;
}
.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}
</style>
